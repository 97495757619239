.Options-presets {
  margin-top: 40px;
  padding-top: 15px;
  background-color: white; }
  .Options-presets .option-field-container {
    width: 100% !important;
    padding: 0 20px; }

.Options-inputs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white;
  margin-top: 40px;
  padding: 20px 0 10px; }
  @media screen and (min-width: 850px) {
    .Options-inputs {
      flex-direction: row;
      justify-content: space-between; } }
  .Options-inputs .option-group {
    margin-bottom: 20px; }
    .Options-inputs .option-group .option-field-container {
      width: 100% !important;
      padding: 0 20px; }
    .Options-inputs .option-group .gifts-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      font-size: 16px; }
    .Options-inputs .option-group.full-width {
      width: 100% !important; }
    .Options-inputs .option-group.gifts .option-field-container.checkbox {
      margin: 5px 0; }
      @media screen and (min-width: 850px) {
        .Options-inputs .option-group.gifts .option-field-container.checkbox {
          width: 50% !important; } }
    .Options-inputs .option-group.radio-group .option-field-container {
      width: 50% !important;
      display: flex;
      flex-wrap: wrap;
      padding-top: 10px; }
      .Options-inputs .option-group.radio-group .option-field-container .radio-button {
        flex: 1;
        width: auto !important; }
    @media screen and (min-width: 850px) {
      .Options-inputs .option-group {
        width: 50%; } }

.Options-submit-container {
  margin-top: 40px;
  display: flex;
  justify-content: center; }
  .Options-submit-container button {
    color: white;
    font-size: 16px !important; }
