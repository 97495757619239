.OptionLabel-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px 20px; }
  .OptionLabel-container h3 {
    color: #333;
    padding: 0;
    margin-left: 10px;
    width: auto;
    display: inline-block;
    font-size: 18px; }
  .OptionLabel-container .error-text {
    font-size: 14px;
    margin-left: 15px; }
