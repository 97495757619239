.Upgrade {
  margin-bottom: 30px; }
  .Upgrade-heading {
    margin-bottom: 30px;
    color: #333;
    font-weight: bold; }
  .Upgrade-package-heading {
    text-align: center; }
  .Upgrade-small-heading {
    display: flex;
    align-items: center;
    margin-top: 25px; }
    .Upgrade-small-heading h4 {
      margin-left: 10px;
      margin-top: 2px;
      font-size: 16px;
      font-weight: bold;
      color: #333; }
  .Upgrade-number-container {
    max-height: 70px;
    display: flex;
    align-items: flex-end; }
    .Upgrade-number-container .button {
      margin-left: 25px; }
  .Upgrade-buttons {
    margin-top: 50px;
    display: flex;
    justify-content: center; }
  .Upgrade .input-field {
    margin-right: 30px; }
