.BillingCard {
  display: flex; }
  .BillingCard-card {
    display: inline-blocf;
    width: 50%; }
    .BillingCard-card .rccs {
      margin: 0 0 0 20px !important; }
  .BillingCard-fields {
    width: 50%;
    display: inline-flex;
    flex-wrap: wrap; }
  .BillingCard-field {
    margin-right: 30px;
    flex-basis: calc(50% - 30px); }
  @media screen and (max-width: 950px) {
    .BillingCard {
      flex-direction: column;
      margin-bottom: 0; }
      .BillingCard-card, .BillingCard-fields {
        width: 100%; }
      .BillingCard-card .rccs {
        margin: 0 !important; }
      .BillingCard-fields {
        margin-top: 20px;
        order: 2; } }
